"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { PageLocker } from "project/general/js/page-locker";
import { getParamsFromQueryString } from "project/general/js/url-helper";
export default class SearchFormComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "handleOpenSearch", () => {
      this.pageLocker.lock();
      this.refs.searchPopup.classList.add("visible");
      setTimeout(() => {
        this.refs.searchInput.focus();
      }, 100);
    });
    __publicField(this, "handleCloseSearch", () => {
      this.pageLocker.unlock();
      this.refs.searchPopup.classList.remove("visible");
    });
    this.pageLocker = new PageLocker();
    this.pageLocker.setOptions({ target: document.body });
  }
  static getNamespace() {
    return "search-form";
  }
  static getRequiredRefs() {
    return [
      "searchPopup",
      "searchButtons",
      "closeButtons",
      "searchForm",
      "searchInput",
      "searchSubmit"
    ];
  }
  onInit() {
    this.refs.searchPopup.removeAttribute("style");
    this._addListeners();
    if (this.isEmptySearchPage()) {
      this.handleOpenSearch();
      document.body.classList.add("search-empty-results");
    }
  }
  _addListeners() {
    this.refs.searchButtons.forEach((button) => {
      this.addListener(button, "click", this.handleOpenSearch);
    });
    this.refs.closeButtons.forEach((button) => {
      this.addListener(button, "click", this.handleCloseSearch);
    });
  }
  isEmptySearchPage() {
    const query = window.location.search.split("?")[1] ? getParamsFromQueryString(window.location.search.split("?")[1]) : {};
    return window.location.pathname === "/design/search" && (query === void 0 || query.q === "" || query.q === void 0);
  }
}
